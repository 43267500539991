export const SHOW_LOADER = 'showLoader';
export const HIDE_LOADER = 'hideLoader';
export const SHOW_SESSION_END_MODAL = 'showSessionEndModal';
export const SHOW_ERROR_MODAL = 'showErrorModal';
export const HIDE_ERROR_MODAL = 'hideErrorModal';
export const HIDE_CONSENTS_MODAL = 'hideConsentsModal';
export const SHOW_CRITICAL_ERROR_MODAL = 'showCriticalErrorModal';
export const SHOW_CREATE_USER_MODAL = 'showCreateUserModal';
export const HIDE_CREATE_USER_MODAL = 'hideCreateUserModal';
export const SHOW_CREATE_AGENCY_MODAL = 'showCreateAgencyModal';
export const HIDE_CREATE_AGENCY_MODAL = 'hideCreateAgencyModal';
export const SHOW_CONTEST_MODAL = 'showContestModal';
export const HIDE_CONTEST_MODAL = 'hideContestModal';

export const SHOW_OK_MODAL = 'showOkModal';
export const HIDE_OK_MODAL = 'hideOkModal';

export const SHOW_DISABLED_USER_MODAL = 'showDisabledUserModal';
export const HIDE_DISABLED_USER_MODAL = 'hideDisabledUserModal';

export const SHOW_EDIT_USER_MODAL = 'showEditUserModal';
export const HIDE_EDIT_USER_MODAL = 'hideEditUserModal';

export const SHOW_VALIDATION_MODAL = 'showValidationModal';
export const HIDE_VALIDATION_MODAL = 'hideValidationModal';

export const PREPARE_FINALIZE = 'prepareFinalize';
export const CLEAR_CREDIT_DATA = 'clearCreditData';

export const CHANGE_CONSENT = 'changeConsent';

export const SET_USER_EMAIL = 'setUserEmail';

export const SHOW_COMPANY_DETAILS = 'showCompanyDetails';
export const ENABLE_COMPANY_DETAILS = 'enableCompanyDetails';
export const HIDE_COMPANY_DETAILS = 'hideCompanyDetails';

export const SET_PARTNER = 'setPartner';
