import * as types from './actionTypes/creditActionTypes'
import { HTTP } from "@/services/http-common";
import * as mutations from "@/store/mutations/mutation-types";
import router from "@/router";
import { confirmContract, drawBank, initApplication, waitForDecision } from "@/constants/mockData"

function processResponse({ state, commit }, data, onSuccess, onError) {
  if (data.responseStatus === 'OK') {
    onSuccess(data);
  } else {
    if (data.responseStatus === 'ERROR' && data.errCode === "ER_GENERAL") {
      commit(mutations.HIDE_LOADER);
      commit(mutations.SHOW_ERROR_MODAL);
    } else if (data.responseStatus === 'ERROR' && (data.errCode === "END_PROCESS" || data.errCode === "ER_WRONG_STATUS")) {
      commit(mutations.HIDE_LOADER);
      commit(mutations.SHOW_ERROR_MODAL);
    }
    onError(data);
  }
}

function processSessionEnd({ state, commit }, error) {
  commit(mutations.HIDE_LOADER);
  if (error.message === "Request failed with status code 403") {
    commit(mutations.SHOW_SESSION_END_MODAL);
  } else {
    commit(mutations.SHOW_ERROR_MODAL);
  }
}

function createSaveOrDrawRequest(state) {
  const { clientData, loanItem, clientType, companyData, processId, insurer } = state.credit;

  const addressList = [{
    addressType: "MAIN",
    street: clientData.mainAddress.street ? clientData.mainAddress.street.trim() : null,
    apartment: clientData.mainAddress.apartment ? clientData.mainAddress.apartment.trim() : null,
    zipCode: clientData.mainAddress.zipCode.trim(),
    city: clientData.mainAddress.city.trim(),
    house: clientData.mainAddress.house.trim(),
  }];
  if (clientData.differentCorrespondence) {
    addressList.push({
      addressType: "CORRESPONDENCE",
      street: clientData.correspondenceAddress.street ? clientData.correspondenceAddress.street.trim() : null,
      apartment: clientData.correspondenceAddress.apartment ? clientData.correspondenceAddress.apartment.trim() : null,
      zipCode: clientData.correspondenceAddress.zipCode.trim(),
      city: clientData.correspondenceAddress.city.trim(),
      house: clientData.correspondenceAddress.house.trim(),
    })
  }

  const companyDetailsData = Object.assign({},
    state.credit.companyData,
    { nip: clientType === "BUSINESS" ? companyData.nip.replaceAll(" ", "").replaceAll("-", "") : null }
  );

  return Object.assign({
    isBusinessCustomer: clientType === "BUSINESS",
    companyDetailsData: clientType === "BUSINESS" ? companyDetailsData : null,
    clientDetailsData: {
      firstName: clientData.firstName.trim(),
      lastName: clientData.lastName.trim(),
      pesel: clientData.pesel.trim(),
      cellphone: clientData.cellphone.replaceAll(" ", "").replaceAll("-", ""),
      monthlyIncome: clientData.monthlyIncome ? clientData.monthlyIncome.trim() : null,
      birthCountry: clientData.birthCountry ? clientData.birthCountry : null,
      email: clientData.email ? clientData.email.trim() : null
    },
    addressList,
    loanDetailsData: Object.assign({
      insurerCode: insurer,
      amount: loanItem.amount,
    }, loanItem.insuranceType === "VEHICLE" ? { vehicle: loanItem.vehicle } : { property: loanItem.property })
  }, !!processId ? { processId } : {});
}


const creditActions = {
  [types.GET_PROCESS_OPTIONS]({ commit, state }) {
    if (process.env.NODE_ENV === "development") {
      commit(mutations.HIDE_LOADER);
      state.credit.isBusinessAvailable = true;
      router.push({ name: "ClientData" }).catch(() => {
      })
    } else {
      HTTP.post('/api/application/getProcessOptions', {})
        .then(response => {
          const onSuccess = (data) => {
            commit(mutations.HIDE_LOADER);
            state.credit.isBusinessAvailable = data.businessAvailable;
            router.push({ name: "ClientData" }).catch(() => {
            })
          };
          const onError = (data) => {
          };
          processResponse({ state, commit }, response.data, onSuccess, onError);
        })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.FIND_COMPANY]({ commit, state }) {
    if (process.env.NODE_ENV === "development") {
      commit(mutations.HIDE_LOADER);
      state.credit.companyData = {
        name: "Testowa Działalność",
        nip: state.credit.companyData.nip,
        city: "Warszawa",
        street: "Żupnicza",
        house: "17",
        apartment: "4",
        zipCode: "02-123"
      };
      commit(mutations.SHOW_COMPANY_DETAILS);
    } else {
      HTTP.post('/api/application/findCompany', { nip: state.credit.companyData.nip.replaceAll(" ", "").replaceAll("-", "") })
        .then(response => {
          const onSuccess = (data) => {
            commit(mutations.HIDE_LOADER);
            state.credit.companyData = {
              name: data.name,
              nip: state.credit.companyData.nip,
              city: data.city,
              street: !!data.street ? data.street.replace("ul. ", "").replace("al. ", "").replace("pl. ", "").replace("Al. ", "") : null,
              house: data.house,
              apartment: data.apartment,
              zipCode: data.zipCode.substr(0, 2) + "-" + data.zipCode.substr(2)
            };
            commit(mutations.SHOW_COMPANY_DETAILS);
            if (!data.city) {
              commit(mutations.ENABLE_COMPANY_DETAILS)
            }
          };
          const onError = (data) => {
            if (data.responseStatus === 'ERROR' && data.errCode === "ER_NOT_JDG") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, ["Podany NIP nie należy do Jednoosobowej Działaności Gospodarczej."]);
            }
            commit(mutations.ENABLE_COMPANY_DETAILS)
          };
          processResponse({ state, commit }, response.data, onSuccess, onError);
        })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.DRAW_BANK]({ commit, state, dispatch }) {
    const request = createSaveOrDrawRequest(state);

    if (process.env.NODE_ENV === "development") {
      state.credit.processId = drawBank.processId;
      state.bankCode = drawBank.bankOptions.bankCode;
      state.bankList = drawBank.bankOptions.bankList;
      state.currentStep = "INIT_APPLICATION";
      if (drawBank.bankOptions.bankList.length === 0) {
        if (state.partner === 'UNI' && new RegExp('Trasti', 'i').test(state.credit.insurer)) {
          commit(mutations.HIDE_LOADER);
          router.push({ name: "BankChooser" }).catch(() => {
          });
        } else {
          state.currentStep = "INIT_APPLICATION";
          dispatch(types.INIT_APPLICATION)
        }
      } else {
        commit(mutations.HIDE_LOADER);
        router.push({ name: "BankChooser" }).catch(() => {
        });
      }
    } else {
      HTTP.post('/api/application/drawBank', request)
        .then(response => {
          const onSuccess = (data) => {
            state.credit.processId = data.processId;
            state.bankCode = data.bankOptions.bankCode;
            state.bankList = data.bankOptions.bankList;
            state.currentStep = "INIT_APPLICATION";
            if (data.bankOptions.bankList.length === 0) {
              if (state.partner === 'UNI' && new RegExp('Trasti', 'i').test(state.credit.insurer)) {
                commit(mutations.HIDE_LOADER);
                router.push({ name: "BankChooser" }).catch(() => {
                });
              } else {
                state.currentStep = "INIT_APPLICATION";
                dispatch(types.INIT_APPLICATION)
              }
            } else {
              commit(mutations.HIDE_LOADER);
              router.push({ name: "BankChooser" }).catch(() => {
              });
            }
          };
          const onError = (data) => {
          };
          processResponse({ state, commit }, response.data, onSuccess, onError);
        })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.INIT_APPLICATION]({ commit, state, dispatch }) {
    if (process.env.NODE_ENV === "development") {
      state.commissionEligible = initApplication.commissionEligible;
      state.disclaimer = initApplication.disclaimer;
      state.consents = initApplication.consentList;
      state.consentGroups = initApplication.consentGroups;
      state.consentList = initApplication.consentList.map(({ code, description, mandatory, defaultState }) => {
          return {
            code,
            value: defaultState
          }
        }
      );
      state.showConsentModal = true;
      state.currentStep = "BEGIN_APPLICATION";
      commit(mutations.HIDE_LOADER);
      router.push({ name: "BeginApplication" }).catch(() => {
      });
    } else {
      HTTP.post('/api/application/initApplication', {
        bankCode: state.bankCode,
        processId: state.credit.processId,
        insurerName: state.credit.insurer,
        isBusinessCustomer: state.credit.clientType === "BUSINESS"
      })
        .then(response => {
          const onSuccess = (data) => {
            state.commissionEligible = data.commissionEligible;
            state.disclaimer = data.disclaimer;
            state.consents = data.consentList;
            state.consentGroups = data.consentGroups;
            state.consentList = data.consentList.map(({ code, description, mandatory, defaultState }) => {
                return {
                  code,
                  value: defaultState
                }
              }
            );
            state.showConsentModal = true;
            state.currentStep = "BEGIN_APPLICATION";
            commit(mutations.HIDE_LOADER);
            router.push({ name: "BeginApplication" }).catch(() => {
            });
          };
          const onError = (data) => {
          };
          processResponse({ state, commit }, response.data, onSuccess, onError);
        })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.BEGIN_APPLICATION]({ commit, state, dispatch }) {
    const clientData = state.credit.clientData;

    const request = {
      processId: state.credit.processId,
      consentList: state.consentList,
      trastiOverride: state.isTrasti,
      identityDocument: {
        id: clientData.identityDocumentData.id ? clientData.identityDocumentData.id.trim() : null,
        issueDate: clientData.identityDocumentData.issueDate ? clientData.identityDocumentData.issueDate : null,
        expiryDate: clientData.identityDocumentData.expiryDate ? clientData.identityDocumentData.expiryDate : null,
        indefinite: clientData.identityDocumentData.indefinite ? clientData.identityDocumentData.indefinite : null,
      }
    };

    if (process.env.NODE_ENV === "development") {
      state.decisionData.decision = waitForDecision.decision;
      state.decisionData.decisionParameters = waitForDecision.decisionParameters;
      state.currentStep = "CONFIRM_CONDITIONS";
      dispatch(types.CONFIRM_CONDITIONS);
    } else {
      HTTP.post('/api/application/beginApplication', request)
        .then(response => {
          const onSuccess = (data) => {
            if (state.bankCode === "DRR") {
              state.currentStep = "WAIT_FOR_DECISION";
              dispatch(types.WAIT_FOR_DECISION);
            } else {
              state.currentStep = "CONFIRM_APPLICATION";
              commit(mutations.HIDE_LOADER);
              router.push({ name: "ConfirmApplication" }).catch()
            }
          };
          const onError = (data) => {
            if (data.responseStatus === 'ERROR' && data.errCode === "ER_BAD_CONTACT_DETAILS") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Niepoprawne dane teleadresowe Klienta.",
                "Numer telefonu i adres mailowy musi być unikalny dla każdego Klienta.",
                "Popraw dane lub skontaktuj się z infolinią hiPRO 511-810-722"
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_EMAIL_CONFLICT") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Adres email musi być unikalny dla każdego Klienta.",
                "Popraw dane lub skontaktuj się z infolinią hiPRO 511-810-722"
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_MOBILE_CONFLICT") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Numer telefonu musi być unikalny dla każdego Klienta.",
                "Popraw dane lub skontaktuj się z infolinią hiPRO 511-810-722"
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_MOBILE_EMAIL_CONFLICT") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Numer telefonu i adres email muszą być unikalne dla każdego Klienta.",
                "Popraw dane lub skontaktuj się z infolinią hiPRO 511-810-722"
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_BAD_EMAIL") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Nieprawidłowy adres email.",
                "Popraw dane i spróbuj ponownie."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_BAD_PESEL") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Dane w systemie nie odpowiadają podanemu numerowi PESEL.",
                "Popraw dane i spróbuj ponownie."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_BAD_ID") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Dane w systemie nie odpowiadają podanemu numerowi dowodu osobistego.",
                "Popraw dane i spróbuj ponownie."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_COMPANY_ONLY") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Podany adres jest adresem firmowym.",
                "Popraw dane i spróbuj ponownie."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_LASTNAME_NOT_VALID") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Podano nieprawidłowe nazwisko.",
                "Popraw dane i spróbuj ponownie."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_BAD_ZIP_CODE") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Nieprawidłowy kod pocztowy.",
                "Popraw dane i spróbuj ponownie."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_LIVE_LOAN") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Klient ma aktywną pożyczkę.",
                "Dla tego klienta nie można złożyć kolejnego wniosku."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_DRR_ERROR") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Wygląda na to, że zakup polisy z ratami Drobnej Ratki nie jest możliwy."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_WNG_ERROR") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Wygląda na to, że zakup polisy z ratami Wonga nie jest możliwy."
              ]);
            } else if (data.responseStatus === 'ERROR' && data.errCode === "ER_BAD_MOBILE") {
              commit(mutations.HIDE_LOADER);
              commit(mutations.SHOW_ERROR_MODAL, [
                "Nieprawidłowy numer telefonu.",
                "Popraw dane i spróbuj ponownie."
              ]);
              router.push({ name: "BeginApplication" }).catch()
            }
          };
          processResponse({ state, commit }, response.data, onSuccess, onError);
        })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.CONFIRM_APPLICATION]({ commit, state, dispatch }) {
    if (process.env.NODE_ENV === "development") {
      state.currentStep = "WAIT_FOR_DECISION";
      dispatch(types.WAIT_FOR_DECISION);
    } else {
      HTTP.post('/api/application/confirmApplication', {
          pin: state.credit.pinApp,
          processId: state.credit.processId
        }
      ).then(response => {
        state.credit.pinApp = "";
        const onSuccess = (response) => {
          state.currentStep = "WAIT_FOR_DECISION";
          dispatch(types.WAIT_FOR_DECISION);
        };
        const onError = (data) => {
          commit(mutations.HIDE_LOADER);
          if (data.responseStatus === 'ERROR') {
            if (data.errCode === "ER_BAD_PIN") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod PIN jest nieprawidłowy."]);
            }
            if (data.errCode === "ER_BAD_PIN_REQUEST_NEW") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod PIN jest nieprawidłowy, proszę wygenerować nowy."]);
            }
            if (data.errCode === "ER_PIN_EXPIRED") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod jest przeterminowany, proszę czekać na otrzymanie sms z nowym kodem PIN."]);
            }
            if (data.errCode === "ER_PIN_EXPIRED_REQUEST_NEW") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod jest nieważny, proszę wygenerować nowy."]);
            }
            if (data.errCode === "ER_CONTEST_REGISTRATION") {
              commit(mutations.SHOW_ERROR_MODAL, ["Wystąpił błąd przy rejestracji w konkursie, proszę podać poprany numer telefonu."]);
            }
          }
        };
        processResponse({ state, commit }, response.data, onSuccess, onError);
      })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.CONFIRM_CONDITIONS]({ commit, state, dispatch }) {
    if (process.env.NODE_ENV === "development") {
      state.currentStep = "CONFIRM_CONTRACT";
      commit(mutations.HIDE_LOADER);
      state.documentList = confirmContract.documentTypes;
      state.agentCellphone = confirmContract.agentCellphone;
      router.push({ name: "ConfirmContract" }).catch(() => {
      });
    } else {
      commit(mutations.SHOW_LOADER, "Trwa oczekiwanie na odpowiedź banku");
      HTTP.post('/api/application/confirmConditions', { processId: state.credit.processId })
        .then(response => {
          const onSuccess = (response) => {
            state.currentStep = "WAIT_FOR_CONTRACT";
            dispatch(types.WAIT_FOR_CONTRACT);
          };
          const onError = (response) => {
          };
          processResponse({ state, commit }, response.data, onSuccess, onError);
        })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.CONFIRM_CONTRACT_INNER]({ commit, state, dispatch }) {
    let agentCellphone = "";
    if (state.commissionEligible) {
      if ((state.agentCellphone && state.acceptRegulations) || (!state.agentCellphone && !state.acceptRegulations)) {
        commit(mutations.HIDE_CONTEST_MODAL);
        agentCellphone = state.agentCellphone.split("-").join("").split(" ").join("");
      } else {
        commit(mutations.SHOW_ERROR_MODAL, ["Podaj numer telefonu i zatwierdź Regulamin"]);
        return;
      }
    }
    if (process.env.NODE_ENV === "development") {
      state.currentStep = "FINALIZE";
      commit(mutations.HIDE_LOADER);
      router.push({ name: "Finalize" }).catch(() => {
      })
    } else {
      HTTP.post('/api/application/confirmContract', Object.assign({
          pin: state.credit.pinCon,
          processId: state.credit.processId
        }, state.commissionEligible ?
        {
          agentCellphone: agentCellphone,
          acceptRegulations: state.acceptRegulations
        } : {
          agentCellphone: agentCellphone,
          acceptRegulations: false
        })
      ).then(response => {
        state.credit.pinCon = "";
        const onSuccess = (response) => {
          state.currentStep = "FINALIZE";
          commit(mutations.HIDE_LOADER);
          router.push({ name: "Finalize" }).catch(() => {
          })
        };
        const onError = (data) => {
          commit(mutations.HIDE_LOADER);
          if (data.responseStatus === 'ERROR') {
            if (data.errCode === "ER_BAD_PIN") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod PIN jest nieprawidłowy."]);
            }
            if (data.errCode === "ER_BAD_PIN_REQUEST_NEW") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod PIN jest nieprawidłowy, proszę wygenerować nowy."]);
            }
            if (data.errCode === "ER_PIN_EXPIRED") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod jest przeterminowany, proszę czekać na otrzymanie sms z nowym kodem PIN."]);
            }
            if (data.errCode === "ER_PIN_EXPIRED_REQUEST_NEW") {
              commit(mutations.SHOW_ERROR_MODAL, ["Podany kod jest nieważny, proszę wygenerować nowy."]);
            }
            if (data.errCode === "ER_CONTEST_REGISTRATION") {
              commit(mutations.SHOW_ERROR_MODAL, ["Wystąpił błąd przy rejestracji w konkursie, proszę podać poprany numer telefonu."]);
            }
          }
        };
        processResponse({ state, commit }, response.data, onSuccess, onError);
      })
        .catch(e => {
          processSessionEnd({ state, commit }, e)
        });
    }
  },
  [types.CONFIRM_CONTRACT]({ commit, state, dispatch }) {
    if (state.commissionEligible) {
      commit(mutations.SHOW_CONTEST_MODAL);
    } else {
      dispatch(types.CONFIRM_CONTRACT_INNER);
    }
  },
  [types.REQUEST_NEW_PIN]({ commit, state, dispatch }) {
    HTTP.post('/api/application/requestNewPin', { processId: state.credit.processId })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus !== 'OK') {
          commit(mutations.SHOW_ERROR_MODAL)
        }
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.PREPARE_FINALIZE]({ commit, state, dispatch }) {
    HTTP.post('/api/application/prepareFinalize', { processId: state.credit.processId })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus !== 'OK') {
          commit(mutations.SHOW_ERROR_MODAL)
        } else {
          router.push({ name: "Finalize" }).catch(() => {
          })
        }
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.GET_FINAL_DOCUMENTS]({ commit, state, dispatch }) {
    commit(mutations.SHOW_LOADER, "Trwa oczekiwanie na odpowiedź banku");
    HTTP.post('/api/application/getFinalDocuments', { processId: state.credit.processId })
      .then(response => {
        const onSuccess = (response) => {
          commit(mutations.HIDE_LOADER);
          state.documentList = response.documentTypes;
          router.push({ name: "Summary" }).catch(() => {
          });
        };
        const onError = () => {
        };
        processResponse({ state, commit }, response.data, onSuccess, onError);
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.WAIT_FOR_DECISION]({ commit, state, dispatch }) {
    commit(mutations.SHOW_LOADER, "Trwa oczekiwanie na odpowiedź banku");
    HTTP.post('/api/application/waitForDecision', { processId: state.credit.processId })
      .then(response => {
        const onSuccess = (data) => {
          state.decisionData.decision = data.decision;
          if (data.decision === "DECLINE") {
            state.currentStep = "REJECTED";
            state.decisionData.rejectionReason = data.rejectionReason;
            state.documentList = [];
            commit(mutations.HIDE_LOADER);
            router.push({ name: "Summary" }).catch(() => {
            });
          } else {
            state.decisionData.decisionParameters = data.decisionParameters;
            state.currentStep = "CONFIRM_CONDITIONS";
            dispatch(types.CONFIRM_CONDITIONS);
          }
        };
        const onError = (data) => {
          if (data.errCode === "ER_RETRY") {
            dispatch(types.WAIT_FOR_DECISION);
          }
        };
        processResponse({ state, commit }, response.data, onSuccess, onError);
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.WAIT_FOR_CONTRACT]({ commit, state, dispatch }) {
    HTTP.post('/api/application/waitForContract', { processId: state.credit.processId })
      .then(response => {
        const onSuccess = (response) => {
          state.currentStep = "CONFIRM_CONTRACT";
          commit(mutations.HIDE_LOADER);
          state.documentList = response.documentTypes;
          state.agentCellphone = response.agentCellphone;
          router.push({ name: "ConfirmContract" }).catch(() => {
          });
        };
        const onError = () => {
          if (response.errCode === "ER_RETRY") {
            dispatch(types.WAIT_FOR_CONTRACT);
          }
        };
        processResponse({ state, commit }, response.data, onSuccess, onError);
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.FINALIZE]({ commit, state, dispatch }) {
    HTTP.post('/api/application/finalize', {
      processId: state.credit.processId,
      policyId: state.credit.policyId,
      accountNumber: state.credit.accountNumber.replaceAll(" ", "").replaceAll("-", "")
    }).then(response => {
      commit(mutations.HIDE_LOADER);
      if (response.data.responseStatus === 'OK') {
        state.currentStep = "FINISHED";
        router.push({ name: "Summary" }).catch(() => {
        })
      } else {
        commit(mutations.SHOW_ERROR_MODAL);
      }
    }).catch(e => {
      processSessionEnd({ state, commit }, e)
    });
  },
  [types.CANCEL_APPLICATION]({ commit, state, dispatch }) {
    HTTP.post('/api/application/cancelApplication', { processId: state.credit.processId })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          router.push("/").catch(() => {
          })
        } else {
          commit(mutations.SHOW_ERROR_MODAL);
        }
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.LOAD_APPLICATION]({ commit, state, dispatch }, processId) {
    HTTP.post('/api/application/loadApplication', { processId })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {

          state.credit.isBusinessAvailable = true;

          const { isBusinessCustomer, companyDetailsData, clientDetailsData, addressList, loanDetailsData, processId } = response.data;

          state.credit.processId = processId;
          state.credit.clientType = isBusinessCustomer ? "BUSINESS" : "PRIVATE";
          state.credit.companyDetailsData = isBusinessCustomer ? companyDetailsData : null;
          state.credit.clientData = Object.assign({}, clientDetailsData,
            {
              identityDocumentData: {
                id: "",
                expiryDate: null,
                issueDate: null,
                indefinite: false
              }
            });
          addressList.forEach(address => {
            if (address.addressType === "MAIN") {
              state.credit.clientData.mainAddress = address
            } else {
              state.credit.clientData.correspondenceAddress = address
            }
          });
          state.credit.loanItem = {
            amount: loanDetailsData.amount,
            vehicle: loanDetailsData.vehicle,
            property: loanDetailsData.property,
            insuranceType: !!loanDetailsData.vehicle ? "VEHICLE" : "PROPERTY"
          };
          state.credit.insurer = loanDetailsData.insurerCode;

          router.push({ name: "ClientData" }).catch(() => {
          })
        } else {
          commit(mutations.SHOW_ERROR_MODAL);
        }
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.SAVE_APPLICATION]({ commit, state, dispatch }) {

    const request = createSaveOrDrawRequest(state);

    HTTP.post('/api/application/saveApplication', request)
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {
          commit(mutations.SHOW_OK_MODAL)
        } else {
          commit(mutations.SHOW_ERROR_MODAL, ["Uzupełnij wszystkie dane przed zapisaniem"]);
        }
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.SHOW_APPLICATION]({ commit, state, dispatch }, processId) {
    HTTP.post('/api/applications/show', { processId: processId })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {

        } else {
          commit(mutations.SHOW_ERROR_MODAL);
        }
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.HIDE_APPLICATION]({ commit, state, dispatch }, processId) {
    HTTP.post('/api/applications/hide', { processId: processId })
      .then(response => {
        commit(mutations.HIDE_LOADER);
        if (response.data.responseStatus === 'OK') {

        } else {
          commit(mutations.SHOW_ERROR_MODAL);
        }
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
  [types.GET_DOCUMENT]({ commit, state, dispatch }) {
  },
  [types.GET_APPLICATIONS]({ commit, state, dispatch }) {
    HTTP.post("/api/applications/getApplicationList")
      .then(response => {
        commit(mutations.HIDE_LOADER);
        const onSuccess = (data) => {
          state.applicationList = data.applicationList;
        };
        const onError = () => {
        };
        processResponse({ state, commit }, response.data, onSuccess, onError)
      })
      .catch(e => {
        processSessionEnd({ state, commit }, e)
      });
  },
};

export default creditActions;
