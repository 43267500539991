import * as types from './mutation-types'
import { emptyCreditData } from "@/constants";

const mutations = {
  [types.SHOW_LOADER](state, message = "") {
    state.loading.message = message;
    state.loading.show = true;
  },
  [types.HIDE_LOADER](state) {
    state.loading.message = "";
    state.loading.show = false;
  },
  [types.SHOW_VALIDATION_MODAL](state, messages = []) {
    state.validationModal.show = true;
    state.validationModal.messages = messages
  },
  [types.HIDE_VALIDATION_MODAL](state) {
    state.validationModal.show = false;
  },
  [types.SHOW_CREATE_USER_MODAL](state) {
    state.showCreateUserModal = true;
  },
  [types.HIDE_CREATE_USER_MODAL](state) {
    state.showCreateUserModal = false;
  },
  [types.SHOW_CREATE_AGENCY_MODAL](state) {
    state.showCreateAgencyModal = true;
  },
  [types.HIDE_CREATE_AGENCY_MODAL](state) {
    state.showCreateAgencyModal = false;
  },
  [types.HIDE_CONSENTS_MODAL](state) {
    const reqConsents = state.consents
      .filter(({ mandatory }) => {
        return mandatory;
      })
      .map(({ code }) => code);
    const checked = state.consentList
      .filter(({ code }) => {
        return reqConsents.includes(code)
      })
      .every(({ value }) => value);
    if (checked) {
      state.showConsentModal = false;
    }
  },
  [types.SHOW_ERROR_MODAL](state, messages = []) {
    state.errorModal.messages = messages;
    state.errorModal.show = true;
  },
  [types.HIDE_ERROR_MODAL](state) {
    state.errorModal.show = false;
    state.errorModal.messages = []
  },
  [types.SHOW_OK_MODAL](state) {
    state.showOkModal = true;
  },
  [types.HIDE_OK_MODAL](state) {
    state.showOkModal = false;
  },
  [types.SHOW_DISABLED_USER_MODAL](state) {
    state.showDisabledUserdModal = true;
  },
  [types.HIDE_DISABLED_USER_MODAL](state) {
    state.showDisabledUserdModal = false;
  },
  [types.SHOW_CRITICAL_ERROR_MODAL](state, messages = []) {
    state.criticalErrorModal.messages = messages;
    state.criticalErrorModal.show = true;
  },
  [types.CHANGE_CONSENT](state, { index, value }) {
    state.consentList[index].value = value;
  },
  [types.SET_USER_EMAIL](state, userEmail) {
    state.userEmail = userEmail;
  },
  [types.PREPARE_FINALIZE](state, { processId, insuranceType }) {
    Object.assign(state.credit, emptyCreditData());
    state.credit.processId = processId;
    state.credit.loanItem.insuranceType = insuranceType;
    state.currentStep = "FINALIZE";
  },
  [types.CLEAR_CREDIT_DATA](state) {
    Object.assign(state.credit, emptyCreditData());
    state.credit.processId = null;
  },
  [types.SHOW_SESSION_END_MODAL](state) {
    state.showSessionEndModal = true
  },
  [types.SHOW_CONTEST_MODAL](state) {
    state.showContestModal = true
  },
  [types.HIDE_CONTEST_MODAL](state) {
    state.showContestModal = false
  },
  [types.SHOW_COMPANY_DETAILS](state) {
    state.showCompanyDetails = true
  },
  [types.ENABLE_COMPANY_DETAILS](state) {
    state.showCompanyDetails = true;
    state.enableCompanyDetails = true;
  },
  [types.HIDE_COMPANY_DETAILS](state) {
    state.showCompanyDetails = false
  },
  [types.SET_PARTNER](state, partner) {
    state.partner = partner
  },

};

export default mutations
