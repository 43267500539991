export default {
  property: [
    "PZU",
    "Ergo",
    "Warta",
    "Axa Ubezpieczenia",
    "Compensa",
    "Allianz",
    "Generali",
    "InterRisk",
    "Uniqa",
    "Link4",
    "Wiener",
    "TUW TUW",
    "PTR",
    "Aviva",
    "PKO",
    "Concordia",
    "Euler Hermes",
    "TUW PZU",
    "TUZ TUW",
    "Pocztowe",
    "Europa",
    "TUW PG",
    "Saltus",
    "Inter",
    "Cuprum",
    "Santander Aviva",
    "KUKE",
    "Zdrowie",
    "Signal Iduna",
    "Credit Agricole",
    "Nationale Nederlanden",
    "Partner",
    "D.A.S.",
    "TUW Medicum",
    "Helvetia"
  ],
  vehicle: [
    "TUZ TUW",
    "EUROINS",
    "PZU",
    "Warta",
    "Hestia",
    "Ergo Hestia",
    "Axa Ubezpieczenia",
    "Compensa",
    "Allianz",
    "Trasti",
    "Generali",
    "Link4",
    "Wiener",
    "Uniqa",
    "InterRisk",
    "TUW TUW",
    "Aviva",
    "Concordia",
    "Pocztowe",
    "TUW PG",
    "TUW PZU",
    "Helvetia",
    "Benefia",
    "Wefox",
    "BeeSafe"
  ]
};
