<template>
  <div style="height: 100%">
    <top-bar/>
    <v-toolbar light color="white" height="80">
      <v-row>
        <v-col align-self="center">
          <v-btn left @click="back" v-if="backAvailable" color="white" class="mr-2">
            <v-icon>mdi-chevron-left</v-icon>
            Wstecz
          </v-btn>
          <v-btn left @click="cancel" v-if="cancelAvailable" color="white">
            <template v-if="currentStep ==='FINALIZE'">Anuluj wniosek</template>
            <template v-else>Anuluj</template>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="7">
          <stepper/>
        </v-col>
        <v-col class="text-right" align-self="center">
          <v-btn color="primary" @click="next">Dalej
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-card color="#F2F4F7" outlined class="pl-8 pr-10 pt-4 pb-2 support_card">
      <p class="text-h6">Wsparcie</p>
      <p class="text-body-2">
        <v-icon small>mdi-phone-outline</v-icon>
        +48 511 810 722
      </p>
      <p class="text-body-2">
        <v-icon small>mdi-email-outline</v-icon>
        <a class="no-style-link" href="mailto:wsparcie@hipro.pl"> wsparcie@hipro.pl</a>
      </p>
    </v-card>
    <router-view/>
    <validation-modal/>
    <loader/>
    <error-modal/>
    <ok-modal/>
  </div>
</template>

<script>
  import TopBar from "@/components/bars/TopBar";
  import Stepper from "@/components/bars/Stepper";
  import { mapActions } from "vuex";
  import * as actions from "@/store/actions/action-types";
  import { stepList } from "@/constants/creditProcessStages";
  import Loader from "@/components/modals/Loader";
  import ValidationModal from "@/components/modals/ValidationModal";
  import ErrorModal from "@/components/modals/ErrorModal";
  import OkModal from "@/components/modals/OkModal";

  export default {
    name: "Credit",
    components: { OkModal, ErrorModal, ValidationModal, Loader, Stepper, TopBar },
    computed: {
      currentStep() {
        return this.$store.state.currentStep;
      },
      backAvailable() {
        const current = stepList.find(({ step }) => step === this.currentStep);
        if (current) {
          return current.backAvailable;
        } else {
          return false;
        }
      },
      cancelAvailable() {
        const current = stepList.find(({ step }) => step === this.currentStep);
        if (current) {
          return current.cancelAvailable;
        } else {
          return false;
        }
      }
    },
    methods: mapActions({
      next: actions.CREDIT_NEXT,
      cancel: actions.CANCEL_APPLICATION,
      back: actions.CREDIT_BACK
    }),
  }
</script>

<style scoped>
  .support_card {
    position: fixed;
    bottom: 35px;
    left: 35px;
  }

  .no-style-link {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
  }

</style>
